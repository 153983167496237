.parallax{
  background-image: url(../images/profile.jpg);
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;
  background-attachment: fixed;
}

.left{
  text-align: left;
}
