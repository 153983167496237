.modal {
  height: 0;
  width: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.pop-up-background {
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: 0.1s ease-in;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(40, 40, 40, .9);
}

.pop-up {
  margin: auto;
}

