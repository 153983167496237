/* Reset classes */
ul{
    list-style-type: none;
    /* overflow: visible;   */
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

/* Background Shaders */
.shade-1{
    --BG-light:-5%;
    background-color: var(--primary-color);
}

.shade-2{
    --BG-light:-10%;
}

.shade-3{
    --BG-light:-15%;
}

.shade-4{
    --BG-light:-20%;
}

.lighter-1 {
    --BG-light:5%;
}

.lighter-2{
    --BG-light:10%;
}

.lighter-3{
    --BG-light:15%;
}

.lighter-4{
    --BG-light:20%;
}

/* Sizing */
.centered{
    width: max-content;
    margin:auto ;
}
.y-25{
    min-height: 25%;
}
.x-50{
    max-width: 50%;
}

.x-50-min{
    min-width: 50%;
}
.x-25{
    max-width: 25%;
}

.x-25-min{
    min-width: 25%;
}
.flex-1{
    flex-grow: 1;
}
.margin-90{
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
}
.page{
    width: 100%;
}

.risen{
    box-shadow:  4px 5px 0 rgba(0,0,0,.141),0 1px 10px 0 rgba(0,0,0,.122),0 2px 4px -1px rgba(0,0,0,.2);
}
.loading{
    height: 50px;
    aspect-ratio: 1;
    margin: 0 auto;
    position: relative;
    /* transform-origin: center; */
    animation: .5s spinning linear infinite;
}
.loading-circle {
    height: 50px;
    margin: 0 auto;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: transparent;
    border: white solid 5px;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: .5s spinning linear infinite;

}
.ball {   
    position: absolute;
    top: 40%;
    left: 40%;
    /* box-sizing: border-box; */
    margin: 0 auto;
    /* transform-origin: center; */
    transform: rotate(calc(var(--rotation-step) * 40deg)) translateY(25px);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    --font-hue:240;
    --font-sat:calc(var(--rotation-step) * 5%);
    --font-light:calc(var(--rotation-step) * 5%);
    background-color: var(--font-color);
    
}

/* Flex/Grid classes */
.flex-box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.flex-col{
    display: flex;
}
.flex-spaced{
    justify-content: space-around;
}
/* .expanding {
    transition: .5s ease-in;
    animation: 1s expand forwards;
} */

/* scrolling design */
#accomp{
    /* overflow-y: scroll; */
    max-height: 200px;
}
#accomp::-webkit-scrollbar{
    background-color: transparent;
}
#accomp::-webkit-scrollbar-thumb{
    background-color: var(--accent-color);
    border-radius: 10%;
    height: 10px;
    /* width: 10px; */
}

#accomp::-webkit-scrollbar-thumb:hover{
    background-color: white;
}

/* Dropdown menu */
.dropdown{
    display: none;
    width: max-content;
    position:absolute;
    border-radius: 0 0 5% 5%;
}


.dropdown-container{
    position: relative;
    /* overflow: visible; */
    /* display: inline-block; */
    
}

:hover>.dropdown,
:focus-within>.dropdown
{
    z-index: 1000;
    margin-top: 0;
    --BG-light:5%;
    background-color: var(--primary-color);
    display: inline-block;
    /* width: 100%; */
    /* height: 100%; */
    /* position: fixed; */
    top:100%;
    left: 0 ;
    box-shadow: 0 1px 6px  var(--accent-color) ;
}