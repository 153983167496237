header{
  display: flex;
  flex-direction: row;
  padding: 5px;
  /* overflow:visible; */
  color: var(--font-color);
  background: var(--accent-color);
  /* overflow:scroll; */
  text-align: center;
  min-height: 50px;
  /* height: 250px; */
  position: sticky;
  top:0;
  z-index: 100;
  /* margin-top: 0; */
  margin: 0;
  box-shadow: 2px 10px 20px 4px black;
  /* grid-area: header; */
}

#header-icon{
  border-radius: 50px;
  /* max-height: 100px; */
}
#header-main{
  flex-grow: 2;
  text-align: center;
  /* overflow:scroll; */
  
}
#header-main button:hover {
  --BG-light:10%;
  background-color: var(--primary-color);
}

#nav>li{
  /* overflow:visible; */
  display: inline-block;
  margin-inline-start: 10px;
}

#header-controls{
  flex-direction: column;
  display: flex;
  justify-content: center;
}