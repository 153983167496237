#content{
  flex-grow: 3;
  background-color: var(--primary-color);
  padding-top: 20px;
  padding-bottom: 20px;
}
#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  scroll-snap-type: y mandatory;
}
.dark, .dark * {
  /* Color parameters... */
  --hue:200;
  --sat:15%;
  --light:10%;
  --opacity:1;
  --BG-hue:0;
  --BG-sat:0%;
  --BG-light:0%;
  --BG-opacity:0;
  --accent-hue:0;
  --accent-sat:0%;
  --accent-light:10%;
  --accent-opacity:0;
  --contrast-hue:30;
  --contrast-sat:30%;
  --contrast-light:70%;
  --contrast-opacity:0;
  --font-hue:0;
  --font-sat:0%;
  --font-light:90%;
  --font-opacity:0;
  color: var(--font-color);
}

.light, .light * {
  /* Color parameters... */
  --hue:210;
  --sat:0%;
  --light:80%;
  --opacity:1;
  --BG-hue:0;
  --BG-sat:0%;
  --BG-light:0%;
  --BG-opacity:0;
  --accent-hue:0;
  --accent-sat:50%;
  --accent-light:-30%;
  --accent-opacity:0;
  --contrast-hue:0;
  --contrast-sat:50%;
  --contrast-light:-50%;
  --contrast-opacity:0;
  --font-hue:0;
  --font-sat:0%;
  --font-light:-100%;
  --font-opacity:0;

  color: var(--font-color);
}
