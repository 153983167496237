.hero {
  padding: 1rem;

  display: grid;
  align-content: center;
  grid-gap: 1rem;
  /* margin:1rem; */
  background: linear-gradient(-45deg, hsla(0,80%,40%,1),hsla(230,80%,30%,1));
  /* background-color: var(--accent-color); */
  /* border-radius: 1rem; */
  align-items: center;
  grid-template-columns: 100%;
  border-radius: 25px;
  box-shadow: 5px 5px 5px black;
  outline: 1px white solid;
}
#intro {
  /* background-color:  */
  text-align: left;
  white-space: max-content;
  padding: 1rem;
  /* font-size: 100%; */
}
#site-name {
  color: var(--font-color);
  text-shadow: 5px 5px 2px var(--accent-color);
  /* animation: rotation 3s linear infinite; */

  /* white-space: nowrap; */
}
#profile {
  overflow: hidden;
  margin: auto;
  z-index: 1;
  
  /* border-radius:1rem; */
}
#profile img {
  border-radius: 1rem;
  
  border:1px solid white;
}
@media (min-width: 700px) {
  .hero {
    grid-template-columns:
      350px
      auto;
  }
}
