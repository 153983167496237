.footer {
  bottom: 0px;
  height: 150px;
  width: 100%;
  margin-top: auto;
  transform-style: preserve-3d;
  perspective: 100rem;
  perspective-origin: 50% 50%;
  --BG-light:-5%;
  border-top: 5px solid var(--accent-color);
  background-color: var(--primary-color);
  text-align: center;
}

.footer ul {
  padding: 0;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px auto 0px auto;
  /* margin-inline-start: 0; */
}
.footer li {
  font-size: 20px;
  transition: 0.2s ease-in-out;
  text-align: center;
  height: 30px;
  aspect-ratio: 1;
  vertical-align: middle;
  /* display: inline-block; */
  margin-inline:.5rem;
  padding: 5px;
  border-radius:35%;
  background:var(--contrast-color);
  
}
.footer i{
  margin:auto 0;
  color: var(--primary-color);
}

.footer li:hover {
  transform: scale(1.5);
  --BG-light:3%;
  box-shadow: 2px 2px 1px var(--accent-color), -.25px -.25px 1px var(--font-color);
  background:var(--primary-color);
}

.footer li:hover i {
  transform: scale(1.2);
  color:var(--socialColor);
  --BG-light:-5%;
}