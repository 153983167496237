#services-container{

}

#services-container article{
    border-radius: 10px;
    margin: 100px auto;
    box-shadow: 2px solid grey;
    height: 500px;
    background-image: var(--img);
    background-size: cover;
    background-clip: content-box;
    position: relative;
    background-attachment: fixed;
    counter-increment: articles;
}

#services-container article h1{
    padding-inline: 5px;
    padding-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: brightness(10%);
}

.list-container {
    position: absolute;
    height: 50%;
    width: 100%;
    top:25%;
}

.service-description{
    z-index: 2;
    position: absolute;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    height: 300px;
    font-size: 1.5rem;
    /* top:100%; */
    /* right:50px; */
    backdrop-filter: blur(1px) brightness(5%);
    width: 90%;
    /* margin: 0 auto; */
}
.service-img{
    /* backface-visibility: hidden; */
    height: 100%;
    background-image: var(--img);
    background-size: cover;
    
    filter: grayscale(95%) blur(5px) brightness(60%);   
 }

.list-container ul{
    /* display: none; */
    /* text-align: center; */
    /* line-height: 250px; */
    backdrop-filter: 
    /* blur(5px)  */
    brightness(10%) 
    grayscale(100%);
}

.list-container li{
    padding: 15px 0;
    font-size: 2rem;
    transform: translateX(-100%); 
}

.visible li {
    animation: slideInFromLeft .5s ease-in forwards, pulsing 2s ease-in-out infinite alternate;  
}

.list-container li:nth-child(2) {animation-delay: .5s;}
.list-container li:nth-child(3) {animation-delay: 1s;}
.list-container li:nth-child(4) {animation-delay: 1.5s;}
.list-container li:nth-child(5) {animation-delay: 2s;}
.list-container li:nth-child(6) {transition: transform 2s ease;}

@keyframes slideInFromLeft {
    0% {
        
    }    
    100% {
        transform: translateX(0%);
    }
}

@keyframes pulsing {
    to {
        text-shadow: 1px 1px 5px var(--accent-color)
    }
}
@media (min-width:600px){
    .service-description{
        z-index: 2;
        position: absolute;
        border-radius: 10px;
        overflow: hidden;
        padding: 10px;
        height: 300px;
        font-size: 1.5rem;
        
        right:50px;
        backdrop-filter: blur(1px) brightness(5%);
        width: 30%;
    }
    .visible li {
        animation: slideInFromLeft 1s ease-in forwards, pulsing 2s ease-in-out infinite alternate;;
    }
}

@media (min-width:1000px){
    .service-description{
        width:40%;
        top:-50px;
    }
}