#resume{
    background-color: var(--accent-color);
    /* overflow: hidden; */
    border-radius: 2% 2% 1% 1% / 3% 3% 1% 1%;
    /* padding-inline: 20px;
    padding-bottom: 50px; */
    padding: 0px 20px 0px 0px;
    opacity: 1;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

#cover-letter{
    padding: 20px 20px 25px 20px;
    background-color: var(--primary-color);
}

#cv{
    padding-left: 10px;
}

#resume-title{
    padding:1.5rem 0 0 0;
    text-shadow: 3px 3px 5px var(--accent-color);
    text-align: center;
    font-size: 3rem;
    color: var(--primary-color);
}

.section-title{
    padding: 5px 5px 20px 0px;
}
.section-title::before{
content: '\e51d';
font-family: FontAwesome;
padding-right: 15px;
--font-light:-40%;
color: hsla(210, 30%, 60%, 1)
}

.job-title{
 font-weight: 900;
}

/* .accomplishments span{
    position: absolute;
    opacity: 0;
    left: 25%;
    transition: 1s ease-in-out;
    padding-left: 10px;
} */

.accomplishments{
    padding: 10px 20px;
}
.accomplishments li{
    position:relative;
    padding: 5px 0;
    
}
.accomplishments li::marker{
    content: "\f058";
    font-family: FontAwesome;
}
.jobs{
    
}
.jobs:hover .accomplishments span{
    opacity: 1;
    left: 0%;
    
}
#content:has(#resume){
    background-attachment: fixed;
    background-image: url("../../images/jess-bailey-q10VITrVYUM-unsplash.jpg");
}

