.sidebar-wrapper {
  /* width: 3rem; */
  /* height: 100vh; */
  grid-area: header;
  margin: 0;
  padding: 0;
}
.sidebar-expanded,
.sidebar-collapsed {
  grid-area: header;
  position: -webkit-sticky;
  position: fixed;
  top: 0px;
  left: 0px;

  background: linear-gradient(90deg, var(--primary-color), var(--accent-color));

  z-index: 1000;
  transition: 0.3s ease-in;
  border-radius: 0 0.5rem 0.5rem 0;
  /* overflow: hidden; */
}

#menu-icon {
  margin: 0.5rem 0 0 1rem;
  color: blueviolet;
}
.sidebar-expanded {
  width: 9.5rem;
  height: auto;
}

.sidebar-collapsed {
  width: 3rem;
  height: 2rem;
}
.sidebar-collapsed .sidebar-menu {
  margin: 0;
  transition: 0.3s ease-in;
}
.sidebar-collapsed .sidebar-menu-item {
  font-size: 0rem;
  margin: 0px;
  transition: 0.3s ease-in;
}
.sidebar-text {
  margin-left: 0.25rem;
}
.sidebar-collapsed .sidebar-text {
  font-size: 0px;
}

.sidebar-expanded .sidebar-text {
  font-size: 1rem;
  /* transition-delay: 0.2s; */
}
#menu-icon:hover > bar {
  background: red;
  box-shadow: none;
}
.sidebar-menu {
  padding: 0;

  list-style-type: none;
}
.sidebar-menu-item {
  margin: 0 0 0.5rem 1rem;
  color: var(--font-color);
}
.sidebar-collapsed .toggle-item {
  display: none;
}
